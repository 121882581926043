<template>
  <div class="row" id="topPart">
    <form @submit.prevent="search">
      <div class="row">
        <div class="form-group container col-12">
          <label for="location">Search Location</label>
          <select class="form-control" id="location" v-model="location">
            <option value="All">All</option>
            <option value="Bakuli">Bakuli</option>
            <option value="Bugoloobi">Bugoloobi</option>
            <option value="Bukoto">Bukoto</option>
            <option value="Busega">Busega</option>
            <option value="Butabika">Butabika</option>
            <option value="Bwaise">Bwaise</option>
            <option value="Ggaba">Ggaba</option>
            <option value="Kabalagala">Kabalagala</option>
            <option value="Kaleerwe">Kaleerwe</option>
            <option value="Kampala Hill">Kampala Hill</option>
            <option value="Kamwookya">Kamwookya</option>
            <option value="Kasanga">Kasanga</option>
            <option value="Kanyanya">Kanyanya</option>
            <option value="Kasubi hill">Kasubi hill</option>
            <option value="Katwe">Katwe</option>
            <option value="Kawaala">Kawaala</option>
            <option value="Kawempe">Kawempe</option>
            <option value="Kibuli">Kibuli</option>
            <option value="Kibuye, Uganda">Kibuye, Uganda</option>
            <option value="Kigoowa">Kigoowa</option>
            <option value="Kikaaya">Kikaaya</option>
            <option value="Kisaasi">Kisaasi</option>
            <option value="Kisenyi">Kisenyi</option>
            <option value="Kololo">Kololo</option>
            <option value="Kulambiro">Kulambiro</option>
            <option value="Kyebando">Kyebando</option>
            <option value="Lubaga">Lubaga</option>
            <option value="Lugogo, Kampala">Lugogo, Kampala</option>
            <option value="Lungujja">Lungujja</option>
            <option value="Luzira">Luzira</option>
            <option value="Makerere">Makerere</option>
            <option value="Makerere Kikoni">Makerere Kikoni</option>
            <option value="Makindye">Makindye</option>
            <option value="Mengo">Mengo</option>
            <option value="Mpererwe">Mpererwe</option>
            <option value="Mulago">Mulago</option>
            <option value="Munyonyo">Munyonyo</option>
            <option value="Muyenga">Muyenga</option>
            <option value="Naakulabye">Naakulabye</option>
            <option value="Nakasero">Nakasero</option>
            <option value="Nakawa">Nakawa</option>
            <option value="Namirembe Hill">Namirembe Hill</option>
            <option value="Namungoona">Namungoona</option>
            <option value="Namuwongo">Namuwongo</option>
            <option value="Nateete">Nateete</option>
            <option value="Ndeeba">Ndeeba</option>
            <option value="Nsambya">Nsambya</option>
            <option value="Ntinda">Ntinda</option>
            <option value="Port Bell">Port Bell</option>
            <option value="Wandegeya">Wandegeya</option>
          </select>
        </div>
      </div>

      <div class="row">
        <div class="form-group container col-12">
          <label for="price">Maximum Price</label>
          <input
            type="number"
            class="form-control"
            id="price"
            v-model="price"
          />
        </div>
      </div>

      <div class="row">
        <div class="form-group container col-12">
          <label for="numberOfBedrooms">House Type</label>
          <select
            class="form-control"
            id="numberOfBedrooms"
            v-model="houseType"
          >
            <option value="All">All</option>
            <option value="single">Single</option>
            <option value="1 bedroom and a sitting">1 bedroom & sitting</option>
            <option value="2 bedrooms and a sitting">
              2 bedrooms & sitting
            </option>
            <option value="3 bedrooms and a sitting">
              3 bedrooms & sitting
            </option>
          </select>
        </div>
      </div>

      <button
        type="submit"
        class="btn btn-primary mt-2"
        data-bs-dismiss="modal"
      >
        Search
      </button>
    </form>
  </div>
</template>

<script>
export default {
  emits: ["search"],
  data() {
    return {
      location: "All",
      price: 100000000,
      houseType: "All",
    };
  },
  methods: {
    search() {
      this.$emit("search", {
        location: this.location,
        price: this.price,
        houseType: this.houseType,
      });
    },
  },
};
</script>

<style scoped>
#topPart {
  margin-top: 0;
}
h2 {
  margin: 0.5rem 0;
}

.filter-option {
  margin-right: 1rem;
}

.filter-option label,
.filter-option input {
  vertical-align: middle;
}

.filter-option label {
  margin-left: 0.25rem;
}

.filter-option.active label {
  font-weight: bold;
}

.flex {
  display: flex;
}

.combineForm {
  display: flex;
}

button {
  display: block;
  width: 100%;
}
</style>