<template>
  <div id="theWholeCard">
    <div
      :id="'c' + product.id"
      class="carousel carousel-dark slide"
      data-bs-pause="false"
    >
      <div class="carousel-inner">
        <div
          class="carousel-item imagePart"
          v-for="image in product.bunnyImageUrls"
          :key="image"
          :class="image === product.bunnyImageUrls[0] ? 'active' : ''"
        >
          <router-link :to="productDetail" class="imageContainer">
            <img
              :src="image"
              class="img-fluid rounded d-block"
              :alt="product.id"
            />
            <p class="top-right">
              {{ 1 + product.bunnyImageUrls.indexOf(image) }} /
              {{ product.bunnyImageUrls.length }}
            </p>
          </router-link>
        </div>
      </div>
      <button
        class="carousel-control-prev"
        type="button"
        :data-bs-target="'#c' + product.id"
        data-bs-slide="prev"
      >
        <span
          class="carousel-control-prev-icon"
          aria-hidden="true"
        ></span>
        <span class="visually-hidden">Previous</span>
      </button>
      <button
        class="carousel-control-next"
        @click="thisButton"
        type="button"
        :data-bs-target="'#c' + product.id"
        data-bs-slide="next"
      >
        <span
          class="carousel-control-next-icon"
          aria-hidden="true"
        ></span>
        <span class="visually-hidden">Next</span>
      </button>
    </div>

    <div class="px-4">
      <p class="m-0">{{ product.productName }}</p>
      <p class="m-0">
        <strong
          >{{
            product.price.toLocaleString("UG", {
              style: "currency",
              currency: "UGX",
            })
          }}
        </strong>
      </p>
      <p>
        Contact us on
        <i class="fa-brands fa-whatsapp"></i>
        <a href="https://wa.me/+256751568755/?text=Hello">+256 751568755</a>
        <!-- <br><a href="tel: +256788380658">+256 788380658</a> -->
      </p>
      <div class="row justify-content-between">
        <router-link :to="productLink" class="btn btn-outline-info col-4"
          >Buy</router-link
        >
        <button
          type="button"
          class="btn btn-outline-info col-4"
          data-bs-toggle="modal"
          :data-bs-target="'#m' + product.videoId"
          @click="toggleProductVideo"
          v-if="product.videoId"
        >
          Watch video
        </button>
      </div>

      <!-- Modal -->
      <teleport to="body">
        <div
          class="modal fade myModal"
          :id="'m' + product.videoId"
          tabindex="-1"
          aria-labelledby="myModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-dialog-scrollable modal-fullscreen">
            <div class="modal-content">
              <div class="modal-header p-0 myHeader">
                <h5 class="modal-title" id="videoLable">Video</h5>
                <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  @click="productVideo = false"
                ></button>
              </div>
              <div class="modal-body m-0 increaseHieght">
                <div class="row" v-if="productVideo">
                  <iframe
                    class="col"
                    :src="'https://www.youtube.com/embed/' + product.videoId"
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen
                  >
                  </iframe>
                </div>
              </div>
            </div>
          </div>
        </div>
      </teleport>
    </div>
  </div>
</template>
  
  <script>
export default {
  props: ["product"],
  data() {
    return {
      productVideo: false,
    };
  },
  computed: {
    productLink() {
      return "/marketplace/product/" + this.product.id + "/buy";
    },
    productDetail() {
      return "/marketplace/product/" + this.product.id;
    },
  },
  methods: {
    toggleProductVideo() {
      // console.log('When toggling', this.product.videoId)
      this.productVideo = !this.productVideo;
    },
  },
  created() {
    // console.log("Whenn created", this.product.videoId)
  },
};
</script>
  
  <style scoped>
#theWholeCard {
  max-height: 100vh;
  width: 25rem;
  max-width: 25rem;
}

div .imagePart {
  width: 100%;
  height: 17rem;
}

img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.imageContainer {
  position: relative;
  text-align: center;
  color: white;
}

.top-right {
  position: absolute;
  top: 1rem;
  right: 10rem;
  background-color: rgb(65, 59, 59);
  padding-left: 0.3rem;
  padding-right: 0.5rem;
  border-radius: 0.2rem;
}

a {
  text-decoration: none;
}

.bclour {
  background-color: black;
  border-radius: 0.3rem;
}

.increaseHieght {
  height: 90vh;
  top: 0vh;
  width: 100%;
}
.myHeader {
  margin-bottom: 0;
  margin-right: 1rem;
}

iframe {
  height: 33rem;
  width: 100%;
}

.myModal {
  width: 100%;
}
</style>